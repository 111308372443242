
#about {
  padding-top: 5vh;
  padding-bottom: 5vh;
  color: #696969;
}

#about p {
  font-size: 14px;
}
