.footer {
  position: absolute;
  text-align: center;
}

p {
  font-size: 12px;
  color: #696969;
}

.fa {
  padding: 5px;
  text-align: center;
  text-decoration: none;
  margin: 5px;
  border-radius: 50%;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: white;
}

.fa-skype {
  background: #00aff0;
  color: white;
}

.fa-android {
  background: #a4c639;
  color: white;
}

.fa-vimeo {
  background: #45bbff;
  color: white;
}

.fa-soundcloud {
  background: #ff5500;
  color: white;
}

.fa-rss {
  background: #ff6600;
  color: white;
}
