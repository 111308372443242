
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -5px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-top: 30px;
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your images items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: white;
  margin-bottom: 30px;
  max-width: 100%;
}

/* Optional, different gutter size on mobile */
@media (max-width: 2500px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }
  .my-masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}

/* @media (max-width: 100%) {
  width: calc(100% - 30%);
  padding-top: 30px;
  padding-left: 30px;
} */
