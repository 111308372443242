.navbar {
  color: #696969;
  z-index: 999;
  font-size: 16px;
}

.navbar a:hover {
  color: blue;
}

.collasible-nav-dropdown {
  color: #696969;
  margin-bottom: 5vh;
  text-align: left;
}

.NavDropdown:hover {
  color: blue;
}

.navbar-collapse {
  transform: translateX(0%);
  background: white;
  text-align: center;
  flex-direction: column;
}
