#contactsimple {
  padding-top: 5vh;
  padding-bottom: 5vh;
  color: #696969;
}

#contactsimple p {
  text-align: left;
  font-size: 14px;
}
